import { left } from "@popperjs/core";
import "bootstrap";
import "./index.scss";

var $ = require("jquery");
$(document).ready(function () {
    //const headline = "Welcome!!";
    //$('h1').text(headline);

    //Paralax using simpleparallax.com
    var image = document.getElementsByClassName('paralax');
    new simpleParallax(image, {
        delay: .6,
        transition: 'cubic-bezier(0,0,0,1)'
    });

    //Footer date
    $('.copyright .year').html((new Date).getFullYear());
});


//Animations using scrollrevealjs.org
ScrollReveal().reveal('.sd-animate-slide', { origin: 'left', distance: '300px', duration: 1000 } );
ScrollReveal().reveal('.sd-animate-scale', { scale: 0.85 , duration: 1000, easing: 'ease-in' } );
ScrollReveal().reveal('.sd-animate-opacity', { opacity: 0.0 , duration: 1000, easing: 'ease-in' } );
ScrollReveal().reveal('.sd-animate-spotlight', { distance: '0px', opacity: 0.2 });



